<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Certificate/Examination"
            >考试证书 /
          </router-link>
          <span class="crumbs_item crumbs_last">生成证书</span>
        </span>
      </div>
    </div>
    <!-- 步骤条 -->
    <div class="steps_box">
      <div class="steps_center">
        <div>
          <span class="num num-active">1</span>
          <span class="txt txt-active">考生信息</span>
        </div>
        <span class="arrow arrow-active"></span>
        <div>
          <span class="num num-active">2</span>
          <span class="txt txt-active">证书生成</span>
        </div>
        <span class="arrow arrow-active"></span>
        <div>
          <span class="num">3</span>
          <span class="txt">证书物流</span>
        </div>
      </div>
    </div>
    <!-- 搜索条件 -->
    <div class="query_box">
      <div class="side-left">
        <div class="table-box">
          <a-tabs :default-active-key="0" @change="callback">
            <a-tab-pane
              v-for="(item, index) in tabList"
              :key="index"
              :tab="item"
            ></a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="side-right">
        <a
          :href="
            $config.target +
            '/hxclass-management/exam/certif/export/certifinfo?recordId=' +
            recordId +
            '&type=1'
          "
        >
          <a-button style="margin-right: 10px" class="all_boder_btn btn"
            >下载模板</a-button
          >
        </a>
        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary" v-hasPermi="['certificates:examineecertif:import']">批量导入</a-button>
        </a-upload>
        <a-button
          type="primary"
          style="margin-left: 10px"
          @click="selgenerate(1)"
          class="btn"
          v-hasPermi="['certificates:examcertif:add']"
          >批量生成</a-button
        >
        <a-button
          type="primary"
          style="margin-left: 10px"
          @click="selGenerateAll()"
          class="btn"
          v-hasPermi="['certificates:examcertif:add']"
          >一键生成全部证书</a-button
        >
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: select,
          onSelectAll: selectAll,
          columnTitle: '选择',
        }"
        :rowKey="(item) => item.subId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="footer">
          <a-checkbox
            @change="onChange"
            :checked="isChecked"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 证书模板 -->
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link" v-if="record.generatStatus == '已生成'">
            <div style="position: relative; overflow: hidden">
              <a>查看</a>
              <viewer>
                <img
                  style="
                    position: absolute;
                    top: -10px;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                  "
                  class="inneer_img"
                  :src="record.certifPicture"
                />
              </viewer>
            </div>
            <span v-hasPermi="['certificates:examcertif:download']"> | </span>
            <a
              :href="record.certifPicture"
              :download="record.certifPicture"
              target="_blank"
              v-hasPermi="['certificates:examcertif:download']"
            >
              下载</a
            >
          </div>
          <div class="btn_router_link" v-else>
            <span @click="show(record)">编辑</span>
            <span> | </span>
            <span @click="ifselgenerate(record)">生成</span>
          </div>
        </template>
      </a-table>
    </div>
    <div class="foot_btn">
      <!-- <a-button type="primary" class="btn">暂存</a-button> -->
      <a-button type="primary" class="btn" @click="$router.go(-1)"
        >上一步</a-button
      >
      <a-button type="primary" class="btn" @click="goPage">下一步</a-button>
    </div>
    <!-- 编辑弹窗 -->
    <a-modal
      v-model="EditVisible"
      title="编辑证书信息"
      okText="确认"
      width="550px"
      @ok="edititem()"
    >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">证书编号：</span>
          <a-input
            v-model="formObJ.tripaCertifNumber"
            class="right"
            placeholder="请输入证书编号"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">发证日期：</span>
          <a-month-picker
            v-model="formObJ.generatTime"
            class="right"
            @change="changeStartTime"
            format="YYYY-MM"
          />
        </div>
      </div>
    </a-modal>

    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import moment from "moment";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件

const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考生姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examName",
  },
  {
    title: "考试日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examDateTime",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "tripaCertifNumber",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "certifTime",
  },
  {
    title: "证书生成状态",
    align: "center",
    dataIndex: "generatStatus",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "code9",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "考生姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  // {
  //   title: "考试名称",
  //   align: "center",
  //   dataIndex: "examName",
  // },
  {
    title: "考试日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examDateTime",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "certifNumber",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "generatTime",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columnsss,
      dateFormat: "YYYY:MM:DD",
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数

      ExamVisible: false, // 关联考试弹窗
      EditVisible: false, // 编辑弹窗

      isChecked: false,
      selectedRowKeys: [],

      tabList: ["全部", "待生成", "已生成"], //资讯列表
      status: null,
      formObJ: {
        tripaCertifNumber: "", //证书号
        generatTime: "", // 生成日期
      },
      formif: true,
      // 弹框
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  },
  // 事件处理器
  methods: {
    // 超牛逼一键生成全部证书
    selGenerateAll() {
      if (this.loading) {
        this.$message.warning("请勿连续点击生成证书");
        return;
      }
      this.loading = true;
      this.$ajax({
        url:
          "/hxclass-management/exam/certif/generate-all?recordId=" +
          this.recordId,
        method: "put",
        // params: {
        //   recordId: this.recordId,
        // },
      }).then((res) => {
        this.loading = false;

        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    moment,
    changeStartTime(e, r) {
      this.formObJ.generatTime = r;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 学习人数详情页
    goLearnPage() {
      this.$router.push({ path: "/admin/Certificate/Learner" });
    },
    // 获证人数详情页
    goObtainPage() {
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },
    // tab切换
    callback(e) {
      if (e == 0) {
        e = null;
      } else {
        e--;
      }
      this.status = e;
      this.pageNumber = 1;
      this.getData();
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.subId;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },

    // 证书生成
    goPage() {
      this.$router.push({
        path: "/admin/Certificate/CertificateLogistics",
        query: { recordId: this.recordId },
      });
    },

    // 获取已保存的信息
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/exam/certif/sub/list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          status: this.status,
          recordId: this.recordId,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    ifselgenerate(e) {
      // console.log(e);
      if (!e.tripaCertifNumber && !e.certifTime) {
        this.$message.success("请编辑证书编号和发证时间");
        return;
      }
      let _this = this;
      this.$confirm({
        title: "确定生成?",
        okText: "确认",
        onOk() {
          _this.selgenerate(e);
        },
        onCancel() {},
      });
    },
    // 批量生成证书
    selgenerate(e) {
      if (this.loading) {
        return;
      }
      let arr = undefined;
      if (e == 1) {
        if (!this.selectedRowKeys.length) {
          this.$message.error("请选择用户");
          return;
        }
        arr = this.selectedRowKeys.join(",");
        // this.selectedRowKeys.forEach((element) => {
        //   arr.push({
        //     subId: element,
        //     status: 1,
        //   });
        // });
      } else {
        arr = e.subId;
      }
      // arr = JSON.stringify(arr);
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/exam/certif/user/generat?subIds=" + arr,
        method: "PUT",
        params: {
          // recordId: this.recordId,
          // subIds: arr,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.EditVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //  显示
    show(e) {
      this.EditVisible = true;
      this.formObJ.generatTime = null;
      this.formObJ.tripaCertifNumber = null;
      this.formObJ.recordId = this.recordId;
      // console.log(e);
      let atr = e;
      this.formObJ.generatTime = atr.certifTime;
      this.formObJ.tripaCertifNumber = atr.tripaCertifNumber;
      this.formObJ.subId = e.subId;
    },

    // 编辑考生,没有新建考生每个人都是来传证书编号和日期，其实都是修改操作
    edititem() {
      this.formObJ.generatTime = this.formObJ.generatTime + "-01";
      this.$ajax({
        url: "/hxclass-management/exam/certif/sub/edit",
        method: "put",
        params: this.formObJ,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getData();
          this.EditVisible = false;
          // this.selgenerate(this.formObJ);
        } else {
          this.$message.error(res.message);
          this.EditVisible = false;
        }
      });
    },
    modelhandleCancel() {
      this.importVisible = false;
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/exam/certif/import/certifinfo",
        method: "POST",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          type: 1,
          recordId: this.recordId,
        },
      }).then((res) => {
        // console.log(res);
        this.getData();
        this.successList = res.data.successList;
        this.failureList = res.data.failureList;
        this.importVisible = true;
      });
    },
    // 下载错误excel
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      // window.open(
      //   this.$config.target +
      //     "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
      //     encodeURIComponent(JSON.stringify(this.failureList)) +
      //     "&type=1"
      // );
      this.$ajax({
        url: "/hxclass-management/exam/certif/export/certifinfo",
        method: "post",
        headers: [{ type: "file" }],
        params: {
          errorJson: JSON.stringify(this.failureList),
        },
        responseType: "blob",
      }).then((res) => {
        this.blobDownload(res, "错误文件");
      });
    },
    // blob文件转excel
    blobDownload(data, name) {
      let m = this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name + ".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },
    // 导入正确数据
    importSuccessList() {
      let arr = [];
      this.successList.forEach((element) => {
        arr.push({
          subId: element.subId,
          tripaCertifNumber: element.certifNumber,
          generatTime: element.generatTime + "-01",
        });
      });
      arr = JSON.stringify(arr);
      this.$ajax({
        url: "/hxclass-management/exam/certif/bacth-import",
        method: "POST",
        params: {
          recordId: this.recordId,
          generateJson: arr,
          type: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.recordId = this.$route.query.recordId;
    if (this.recordId != null) {
      this.getData();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./step.less";
a.crumbs_item {
  color: #333;
}
/deep/.table-template .ant-table-footer {
  padding: 16px;
}
/deep/ .ant-pagination {
  margin: 0;
  position: relative;
  bottom: 40px;
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    height: 40px;
    /deep/.ant-select-selection--single {
      height: 40px;
    }
    /deep/.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px;
    }
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
.btn_router_link {
  display: flex;
  justify-items: start;
  justify-content: space-around;
  width: 130px;
  margin: 0 auto;
  span {
    cursor: pointer;
  }
}
</style>
